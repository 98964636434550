import React from "react";
import styled from "styled-components";
import { projects, skills } from "../../data/constants";
import ProjectCards from "../../components/Cards/ProjectCards";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 1;
  align-items: center;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 1100px;
  gap: 12px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

export const Title = styled.div`
  font-size: 42px;
  text-align: center;
  font-weight: 600;
  margin-top: 20px;
  color: ${({ theme }) => theme.text_primary};
  @media (max-width: 768px) {
    margin-top: 12px;
    font-size: 32px;
  }
`;

export const Desc = styled.div`
  font-size: 18px;
  text-align: center;
  max-width: 600px;
  color: ${({ theme }) => theme.text_secondary};
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const ProjectsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  gap: 30px;
  justify-content: center;
`;

const ProjectItem = styled.div`
  width: 100%;
  max-width: 500px;
  background: ${({ theme }) => theme.card};
  border: 0.1px solid #854ce6;
  box-shadow: rgba(23, 92, 230, 0.15) 0px 4px 24px;
  border-radius: 16px;
  padding: 20px 36px;
  @media (max-width: 768px) {
    max-width: 85%;
    // max-width: 400px;
    // padding: 10px 36px;
  }
  @media (max-width: 500px) {
    max-width: 85%;
    // padding: 10px 36px;
  }
`;

const Project = () => {
  return (
    <Container id="project">
      <Wrapper>
        <Title>Project</Title>
        <Desc>Berikut adalah project yang telah saya buat.</Desc>
        <ProjectsContainer>
          {projects.map((project) => (
            <ProjectItem key={project.id}>
              <a
                href={project.github}
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <ProjectCards project={project} />
              </a>
            </ProjectItem>
          ))}
        </ProjectsContainer>
      </Wrapper>
    </Container>
  );
};

export default Project;
