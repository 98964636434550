import React, { useState } from "react";
import styled from "styled-components";
import emailjs from "@emailjs/browser";
import { Alert, Snackbar } from "@mui/material";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 1;
  align-items: center;
  @media (max-width: 960px) {
    padding: 0px;
  }
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 1350px;
  padding: 0px 0px 80px 0px;
  gap: 12px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

const Title = styled.div`
  font-size: 42px;
  text-align: center;
  font-weight: 600;
  margin-top: 20px;
  color: ${({ theme }) => theme.text_primary};
  @media (max-width: 768px) {
    margin-top: 12px;
    font-size: 32px;
  }
`;

const Desc = styled.div`
  font-size: 18px;
  text-align: center;
  max-width: 600px;
  color: ${({ theme }) => theme.text_secondary};
  @media (max-width: 768px) {
    margin-top: 12px;
    font-size: 16px;
  }
`;

const ContactForm = styled.form`
  width: 95%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.card};
  padding: 32px;
  border-radius: 16px;
  box-shadow: rgba(23, 92, 230, 0.15) 0px 4px 24px;
  margin-top: 28px;
  gap: 12px;
`;

const ContactTitle = styled.div`
  font-size: 24px;
  margin-bottom: 6px;
  font-weight: 600;
  color: ${({ theme }) => theme.text_primary};
`;

const ContactInput = styled.input`
  flex: 1;
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.text_secondary};
  outline: none;
  font-size: 18px;
  color: ${({ theme }) => theme.text_primary};
  border-radius: 12px;
  padding: 12px 16px;
  &:focus {
    border: 1px solid ${({ theme }) => theme.primary};
  }
`;

const ContactInputMessage = styled.textarea`
  flex: 1;
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.text_secondary};
  outline: none;
  font-size: 18px;
  color: ${({ theme }) => theme.text_primary};
  border-radius: 12px;
  padding: 12px 16px;
  &:focus {
    border: 1px solid ${({ theme }) => theme.primary};
  }
`;

const ContactButton = styled.button`
  width: 100%;
  text-decoration: none;
  text-align: center;
  background: hsla(271, 100%, 50%, 1);
  background: linear-gradient(
    225deg,
    hsla(271, 100%, 50%, 1) 0%,
    hsla(294, 100%, 50%, 1) 100%
  );
  background: -moz-linear-gradient(
    225deg,
    hsla(271, 100%, 50%, 1) 0%,
    hsla(294, 100%, 50%, 1) 100%
  );
  background: -webkit-linear-gradient(
    225deg,
    hsla(271, 100%, 50%, 1) 0%,
    hsla(294, 100%, 50%, 1) 100%
  );
  padding: 13px 16px;
  margin-top: 2px;
  border-radius: 12px;
  border: none;
  color: ${({ theme }) => theme.text_primary};
  font-size: 18px;
  font-weight: 600;
`;

const Contact = () => {
  const [IsLoading, setIsLoading] = useState(false);
  const [Inputs, setInputs] = useState({
    to_name: "Mardi",
    user_email: "",
    user_name: "",
    subject: "",
    message: "",
  });
  const [infoStatus, setinfoStatus] = useState("");

  const handleChange = (key, event) => {
    const newInputs = { ...Inputs };
    newInputs[key] = event?.target?.value;
    setInputs(newInputs);
  };

  const validateForm = (key) => {
    const validationResults = {};

    if (key.hasOwnProperty("user_email")) {
      validationResults["user_email"] = key["user_email"] === "";
    }

    if (key.hasOwnProperty("user_name")) {
      validationResults["user_name"] = key["user_name"] === "";
    }

    if (key.hasOwnProperty("subject")) {
      validationResults["subject"] = key["subject"] === "";
    }

    if (key.hasOwnProperty("message")) {
      validationResults["message"] = key["message"] === "";
    }

    const result = Object.values(validationResults);
    const find = result.filter((x) => x === true).length;
    return find <= 0 ? true : false;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validate = validateForm(Inputs);
    if (validate) {
      setIsLoading(true);
      emailjs
        .send(
          "mardibl03@gmail.com",
          "template_p60r62m",
          Inputs,
          "Kb5IsW21KfZutB3MN"
        )
        .then(
          () => {
            setIsLoading(false);
            setinfoStatus("Your message has been successfully sent!");
          },
          () => {
            setIsLoading(false);
            setinfoStatus("Your message cannot be sent!!");
          }
        );
    } else {
      alert("Please, complete all fields before sending the message.");
    }
  };

  return (
    <Container>
      <Wrapper>
        <Title>Contact</Title>
        <Desc>
          Jangan ragu untuk menghubungi saya jika ada pertanyaan atau peluang!
        </Desc>
        <ContactForm onSubmit={handleSubmit}>
          <ContactTitle>Email Me 🚀</ContactTitle>
          <ContactInput
            placeholder="Your Email"
            value={Inputs.user_email}
            onChange={(e) => handleChange("user_email", e)}
          />

          <ContactInput
            placeholder="Your Name"
            value={Inputs.user_name}
            onChange={(e) => handleChange("user_name", e)}
          />
          <ContactInput
            placeholder="Subject"
            value={Inputs.subject}
            onChange={(e) => handleChange("subject", e)}
          />
          <ContactInputMessage
            placeholder="Message"
            rows="4"
            value={Inputs.message}
            onChange={(e) => handleChange("message", e)}
          />
          <ContactButton type="submit" value="Send">
            {IsLoading && <i className="fa fa-spinner fa-spin"></i>} Send
          </ContactButton>
        </ContactForm>

        <Snackbar
          open={infoStatus !== ""}
          autoHideDuration={6000}
          onClose={() => setinfoStatus("")}
          message={infoStatus}
          severity="success"
        />
      </Wrapper>
    </Container>
  );
};

export default Contact;
